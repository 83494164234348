.__footer__ {
    background: #3B4C9B;
    border: none;
    box-sizing: border-box;
    color: white;
    height: 160px;
    padding: 40px 0;
    margin-top: auto;
}

.__footer__ p,
.__footer__ a {
    font-size: 12px;
}

.__footer__ a {
    color: #0E9990;
    font-weight: bold;
}
