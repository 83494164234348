.__learn_more_page_navigation__ {
    background: #E1F4F2;
    line-height: 35px;
    padding: 3px 0 0 0;
    margin: 0;
    /** Text **/
    font-family: 'Open Sans', serif;
    font-optical-sizing: auto;
    font-size: 14px;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    font-weight: 600;
    text-align: center;
}

.__learn_more_page_navigation__ .navbar-nav .nav-link.active {
    background: white;
}

.__learn_more_page_navigation__ .navbar-nav .nav-link,
.__learn_more_page_navigation__ .navbar-nav .nav-link.active {
    color: #0E9990;
}

/* Mobile Display */
.__learn_more_page_navigation__ .dropdown-nav {
    color: #0E9990;
}
.__learn_more_page_navigation__ .dropdown-nav .nav-link,
.__learn_more_page_navigation__ .dropdown-nav .trigger-btn {
    color: inherit;
    width: 100%;
    background-color: inherit;
    border: none;
}
.__learn_more_page_navigation__ .dropdown-nav .nav-link.active {
    background-color: white;
}
.__learn_more_page_navigation__ .dropdown-nav .trigger-btn.show {
    border-bottom: 1px solid;
}

@media only screen and (min-width: 768px) {
    .__learn_more_page_navigation__ .navbar-nav .nav-link.active {
        border: 1px solid #36B6A7;
        border-bottom: none;
        border-radius: 10px 10px 0 0;
    }

    .__learn_more_page_navigation__ .nav-link {
        box-sizing: border-box;
        display: inline-block;
        height: 35px;
        padding: 0;
    }
}
