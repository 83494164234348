.__number_scale__ .btn-scale::before {
    counter-increment: values-scale;
    content: counter(values-scale);
}

.__number_scale__ {
    counter-reset: values-scale 0;
    margin: 40px 0;
}

.__number_scale__>h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 30px;
}

.__number_scale__ .scale-label {
    color: #3B4C9B;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 10px;
}

.__number_scale__ .scale .btn-scale.active {
    background-color: #36B6A7;
    color: white;
}

.__number_scale__ .scale .btn-scale {
    background-color: #EDEFF6;
    border: none;
    color: #3B4C9B;
    font-size: 14px;
    font-weight: 600;
    height: 35px;
    line-height: 1.6em;
    margin: 0 2px;
    padding: 6px;
}

.__number_scale__ .btn-group>.btn:first-child,
.__number_scale__ .btn-group>.btn:last-child
{
    border-radius: 20px;
}

.__number_scale__ .btn-group>.btn:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 10px;
}

.__number_scale__ .btn-group>.btn:last-child
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-right: 10px
}

@media print {
    .__number_scale__ .btn-scale.active {
        border: solid #0E9990 1px !important;
        color: #0E9990 !important;
    }

    .__number_scale__ .alert-app-info {
        border: solid #0E9990 1px !important;
    }

    .__number_scale__ {
        padding-bottom: 20px;
        border-bottom: dashed 1px black;
    }
}
