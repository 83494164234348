.__learn_consyderations__ img {
    display: block;
    margin: auto;
}

.__learn_consyderations__ h2 {
    color:  #36B6A7 !important;
    font-size: 20px;
    line-height: 19px;
    margin-bottom: 10px;
}

.__learn_consyderations__ .consideration-point {
    margin-bottom: 50px;
}

.__learn_consyderations__ .top-margin {
    margin-top: 50px;
}
