.__treatment_table__ table {
    border-collapse: separate;
    border-spacing: 10px 0;
    min-width: 770px;
    table-layout: fixed;
}

.__treatment_table__ td {
    margin-right: 10px;
}

.__treatment_table__ .procedure-heading {
    background-color: #36B6A7;
    border-radius: 13px 13px 0 0;
    color: white;
}

.__treatment_table__ table tr td {
    border-left: 1px solid #36B6A7;
    border-right: 1px solid #36B6A7;
}

.__treatment_table__ table tr th,
.__treatment_table__ table tr td {
    border-bottom: 1px dotted #3B4C9B80;
}

.__treatment_table__ .statistic {
    color: #36B6A7;
    font-size: 24px;
}
