.__treatment_options__ img {
    display: block;
}

.__treatment_options__ hr {
    border-color: #3B4C9B17;
}

.__treatment_options__ h4 {
    font-size: 16px;
}

.__treatment_options__ h2 {
    color: #0E9990 !important;
    font-weight: 600 !important;
    font-size: 20px !important;
}

.__treatment_options__ .accordion {
    border: none;
}

.__treatment_options__ .accordion__item {
    margin-bottom: 10px;
}

.__treatment_options__ .accordion__title {
    height: 50px;
    background-color: #3B4C9B17;
    color: #3B4C9B;
    border: none;
}
.__treatment_options__ .accordion__title h3 {
    font-size: 16px;
}

.__treatment_options__ .accordion__body {
    background-color: white;
    border: 1px solid #3B4C9B17;
}

.__treatment_options__ .accordion__title h3:after {
    float: right;
    position: relative;
    top: -8px;
    content: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.82562 11.7994L15 17.9719L21.1744 11.7984L22.5 13.125L15 20.625L7.5 13.125L8.82562 11.7994Z' fill='%233B4C9B'/%3E%3Cpath d='M28.125 15C28.125 22.2375 22.2375 28.125 15 28.125C7.7625 28.125 1.875 22.2375 1.875 15C1.875 7.7625 7.7625 1.875 15 1.875C22.2375 1.875 28.125 7.7625 28.125 15ZM3.75 15C3.75 21.2034 8.79656 26.25 15 26.25C21.2034 26.25 26.25 21.2034 26.25 15C26.25 8.79656 21.2034 3.75 15 3.75C8.79656 3.75 3.75 8.79656 3.75 15Z' fill='%233B4C9B'/%3E%3C/svg%3E%0A");
    width: 30px;
    height: 30px;
}

.__treatment_options__ .accordion__title[aria-selected='true'] h3::after {
    transform: rotate(180deg);
}
