.__page_navigator__ .btn.prev-btn,
.__page_navigator__ .btn.next-btn {
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 600;
}

.__page_navigator__ .pad-left {
    padding-left: 10px;
}

.__page_navigator__ .pad-right {
    padding-right: 10px;
}

@media (min-width: 410px) {
    .__page_navigator__ .pad-left {
        padding-left: 30px;
    }

    .__page_navigator__ .pad-right {
        padding-right: 30px;
    }
}

.__page_navigator__ .prev-btn {
    background: white !important;
    color: #3B4C9B !important;
    border: none;
}

.__page_navigator__ .next-btn:after {
    float: right;
    position: relative;
    height: 20px;
    width: 20px;
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 15L16 12M16 12L13 9M16 12H8M21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12Z' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.__page_navigator__ .prev-btn:before {
    float: left;
    position: relative;
    height: 20px;
    width: 20px;
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 9L8 12M8 12L11 15M8 12H16M21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12Z' stroke='%23252F66' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
