.__app__ {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.__app__ main {
    padding: 20px 15px 80px 15px;
    margin-top: 3px;
}

/** Medium screen sizes **/
@media (min-width: 992px) {
    .__app__ main {
        padding: 20px 80px 80px 80px;
    }
}

.__app__ .alert-app-info ul {
    margin: 0;
}

.__app__ .alert-app-info ul li {
    margin: 18px 0;
}

.__app__ .alert-app-info {
    color: #0E9990;
    background-color: #00A0AA12;
    border: none;
    border-radius: unset;
    padding: 20px 30px;
}

.__app__ .btn-primary {
    color: #fff;
    background-color: #3B4C9B;
    border-color: inherit;
    border-radius: 8px;
    padding: 12px 30px;
    font-size: 14px;
}

/**
 * Colors
 */

.color-primary-blue {
    color: #3B4C9B;
}

.color-primary-teal {
    color: #36B6A7;
}

.color-text-teal, .color-text-teal:hover {
    color: #0E9990;
}

/**
 * Headings
 */

.__app__ h1, .__app__ h2 {
    font-weight: 600;
    color: #3B4C9B;
}

.__app__ h1 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
}

.__app__ h2 {
    font-size: 18px;
    color: #3B4C9B;
}

/**
 * Line Heights
 */
.leading-1 {
    line-height: 19px;
}

p, li {
    margin-bottom: 14px;
}

.weight-400 {
    font-weight: 400;
}

.weight-600 {
    font-weight: 600;
}

.font-style-italic {
    font-style: italic;
}

body, h1, h2, h3, h4, p, li, a, label, th, td {
    /** Text **/
    font-family: 'Open Sans', serif;
    font-optical-sizing: auto;
    font-size: 14px;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    font-weight: 400;
}

.margin-top {
    margin-top: 30px;
}

.margin-bottom-14 {
    margin-bottom: 14px;
}

@media print {
    .no-print {
        display: none;
    }
}
