.__navbar__ {
    background: #3B4C9B;
    line-height: 35px;
    margin: 13px 0 0 0;
    padding: 0;
    /** Text **/
    font-family: 'Open Sans', serif;
    font-optical-sizing: auto;
    font-size: 14px;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    font-weight: 600;
    text-align: center;
}

.__navbar__ .nav-item {
    color: white !important;
}

.__navbar__ .nav-item.active {
    background: #36B6A7;
}

@media only screen and (min-width: 575px) {
    .__navbar__ .nav-item {
        border-left: white solid 1px;
        border-right: white solid 1px;
        box-sizing: border-box;
        display: inline-block;
        height: 35px;
        padding: 0;
        width: 140px;
    }
}

.__navbar__.navbar-dark .navbar-toggler {
    border-color: white;
    color: white;
    margin: 3px;
}
